
import { track, setUserId } from '@amplitude/analytics-browser';
import { ScreenNames } from '../constants';
import { LoggingEventTypes } from './event';
import { cleanPhoneNumber } from '../format'

function trackEvent(event: LoggingEventTypes, parameters: Record<string, string> = {}) {
  track(LoggingEventTypes[event], parameters);
}

function trackScreen(screen: ScreenNames) {
  track(LoggingEventTypes[LoggingEventTypes.PageLoad], { screen, source: "website" });
}

function identifyUser(phoneNumber: string) {
  if (phoneNumber) {
    setUserId(cleanPhoneNumber(phoneNumber))
  }
}

export { trackEvent, trackScreen, identifyUser };
