import { Card } from '../../components/Card';
import { useSchedule } from '../../providers/main';
import { ISteps } from '../../types';
import styles from './styles.module.scss';
import { trackScreen } from '../../utils/logging/logging';
import { useEffect, useState } from 'react';
import { getODCarries } from '../../api/od';
import Select from 'react-select';
import { PRIMARY_INSURANCES_OPTIONS } from '../../utils/insurance';

export const InsuranceCarrierSelection = ({ next, progress, back }: ISteps) => {
  const { updateUser } = useSchedule();

  const [selectedOption, setSelectedOption] = useState({
    value: '',
    label: '',
  });
  const [carrierOptions, setCarrierOptions] = useState([]);
  const [error, setErrorMessage] = useState('');

  useEffect(() => {
    trackScreen('InsuranceCarrierSelectionScreen');
  }, []);

  const fetchOnederfulCarriers = async () => {
    const res = await getODCarries();
    if (res.status === 200) {
      res.json().then(data => {
        setCarrierOptions(data.result);
      });
    } else {
      setErrorMessage('Unable to get carriers');
      console.error(error);
    }
  };

  useEffect(() => {
    fetchOnederfulCarriers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitInsuranceCarrier = () => {
    updateUser({
      insuranceCarrierName: selectedOption.label,
      insuranceCarrierId: selectedOption.value,
    });
    next();
  };

  return (
    <Card
      progress={progress}
      title="Add your dental plan"
      displayNotification={true}
      notificationMessage={
        <>
          <div>Your visit is booked!</div>
          <div>Now, let's add your insurance.</div>
        </>
      }
      showButton
      buttonText="Next"
      buttonDisabled={!selectedOption.value || !selectedOption.label}
      buttonOnClick={submitInsuranceCarrier}
      showSecondaryButton
      secondaryButtonText="I'm not using a dental plan"
      back={back}>
      <div className={styles.container}>
        <div className={styles.insuranceSection}>
          <div className={styles.logosContainer}>
            {PRIMARY_INSURANCES_OPTIONS.map(item => (
              <div
                className={`${styles.logoWrapper} ${
                  selectedOption.value === item.id && styles.selected
                }`}
                onClick={() =>
                  setSelectedOption({ value: item.id, label: item.name })
                }
                key={item.id}>
                <img
                  className={styles.insuranceImg}
                  src={item.logo}
                  alt=""></img>
              </div>
            ))}
          </div>

          <Select
            menuPlacement="top"
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                borderRadius: '16px',
                height: '48px',
                paddingLeft: '16px',
                paddingRight: '16px',
                backgroundColor: '#FCFCFC',
                fontSize: '14px',
                borderColor: '#eaebeb',
              }),
              valueContainer: (baseStyles, state) => ({
                ...baseStyles,
                padding: 0,
              }),
              singleValue: (baseStyles, state) => ({
                ...baseStyles,
                color: '#153260',
              }),
              placeholder: (baseStyles, state) => ({
                ...baseStyles,
                color: '#7F8790',
              }),
              indicatorSeparator: (baseStyles, state) => ({
                ...baseStyles,
                display: 'none',
              }),
              dropdownIndicator: (baseStyles, state) => ({
                ...baseStyles,
                color: '#A0A3A7',
                zIndex: 900,
              }),
              menu: (baseStyles, state) => ({
                ...baseStyles,
                fontSize: '14px',
              }),
            }}
            onChange={option => {
              return setSelectedOption(
                option as { value: string; label: string }
              );
            }}
            options={carrierOptions?.map(x => {
              return { label: (x as any).carrierName, value: (x as any).id };
            })}
            placeholder="View All Carriers"
          />
        </div>
      </div>
    </Card>
  );
};
