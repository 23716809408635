import dayjs from 'dayjs';
import { AppointmentType } from './enums';

export const formatAppointmentName = (apptType: AppointmentType) => {
  switch (apptType) {
    case AppointmentType.FirstVisit:
      return 'Dental Checkup';
    case AppointmentType.CheckUp:
      return 'Dental Checkup';
    case AppointmentType.Emergency:
      return 'Urgent Care';
    case AppointmentType.Ortho:
      return 'Clear Aligner Consult';
  }
};

export const cleanPhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return '';
  }

  return phoneNumber
    .replace(/\s+/g, '')
    .replace(/-/g, '')
    .replace(/\(/g, '')
    .replace(/\)/g, '');
};

export const formatGoogleCalendarLink = (user: any) => {
  const startDate = dayjs(user?.timeslot?.DateTimeStart)
    .toISOString()
    .replaceAll('-', '')
    .replaceAll(':', '')
    .replaceAll('.', '');
  const endDate = dayjs(user?.timeslot?.DateTimeEnd)
    .toISOString()
    .replaceAll('-', '')
    .replaceAll(':', '')
    .replaceAll('.', '');
  const eventName = `Rinse: ${formatAppointmentName(user.service!)}`;

  return `https://calendar.google.com/calendar/r/eventedit?text=${eventName}&dates=${startDate}/${endDate}&location=1763%20Union%20St.%20San%20Francisco%2C%20CA%2094123`;
};
