import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import { MediaInfoType } from '../../types';
import Carousel from 'react-bootstrap/Carousel';
import styles from './styles.module.scss';

export const MediaListDetails = ({
  index,
  files,
  onIndexChange,
}: {
  index: number;
  files: MediaInfoType[];
  onIndexChange: (index: number) => any;
}) => {
  const [activeIndex, setActiveIndex] = useState(index);

  const handleSelect = (selectedIndex: number, e: any) => {
    setActiveIndex(selectedIndex);
    onIndexChange(selectedIndex);
  };

  return (
    <div className={styles.detailCarousel}>
      <Carousel
        slide={false}
        activeIndex={activeIndex}
        onSelect={handleSelect}
        indicators={false}
        controls={files.length > 1}>
        {files.map((f, i) => (
          <Carousel.Item key={i}>
            <img
              key={f.url}
              src={f.url}
              alt=""
              style={{ height: '280px', width: '380px' }}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};
