import { useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import isBetween from 'dayjs/plugin/isBetween';
import { Card } from '../../components/Card';
import { DateCard } from '../../components/DateCard';
import { useSchedule } from '../../providers/main';
import { ISteps, WeekDuration } from '../../types';
import styles from './styles.module.scss';
import ChevronRightIcon from '../../assets/chevron-right.svg';
import ChevronLeftIcon from '../../assets/chevron-left.svg';
import { dateHashBuilder, dateHashDecrypt } from '../../utils/dates';
import toast from 'react-hot-toast';
import { trackEvent, trackScreen } from '../../utils/logging/logging';
import { LoggingEventTypes } from '../../utils/logging/event';
import { AppointmentType, LocalStorageKey } from '../../utils/enums';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(isBetween);

export function DateTime({ next, progress, back, moveToStep }: ISteps) {
  const {
    user,
    updateUser,
    weeks,
    weekTimeSlots,
    isLoadingSlots,
    fetchSpecificAvailability,
    fetchAvailability,
    schedulePlannedAppointment,
    scheduleConsult,
  } = useSchedule();
  const navigate = useNavigate();

  const [selectedWeek, setSelectedWeek] = useState<WeekDuration>(weeks[0]);
  const [selectedWeekSlot, setSelectedWeekSlot] = useState<any>(
    weekTimeSlots[0]
  );
  const [selectedTime, setSelectedTime] = useState<string | null>();
  const [schedulingAppointment, setSchedulingAppointment] = useState(false);

  const { identifier, consultType, userIdentifier } = useParams();
  const [searchParams] = useSearchParams();

  const apptNumber = identifier ? identifier.slice(1) : undefined;
  const isTreatment = apptNumber !== undefined;
  const canBookDirectly = isTreatment || (consultType && userIdentifier);

  function handleSubmit() {
    if (!!selectedTime) {
      const timeslot = dateHashDecrypt(selectedTime);
      updateUser({ timeslot });

      let schedulingPromise = undefined;

      if (isTreatment) {
        const slot = dateHashDecrypt(selectedTime);
        schedulingPromise = schedulePlannedAppointment({
          dateTime: slot.DateTimeStart,
          operatoryNumber: slot.OpNum,
          aptNum: apptNumber!,
          providerNumber: slot.ProvNum,
        });
      } else if (consultType && userIdentifier) {
        schedulingPromise = scheduleConsult(
          {
            ...user,
            timeslot,
          },
          userIdentifier
        );
      } else {
        next();
        return;
      }
      setSchedulingAppointment(true);
      schedulingPromise
        .then((res) => {
          if (res) {
            navigate('/done');
          } else {
            toast.error('Error scheduling the appointment');
          }
        })
        .catch((error: Error) => {
          toast.error('Error ');
        })
        .finally(() => {
          trackEvent(LoggingEventTypes.Book);
          //toast.dismiss();
          setSchedulingAppointment(false);
        });
    } else {
      toast.error('Please, select a time slot');
    }
  }

  const nextWeek = () => {
    const index = weeks.indexOf(selectedWeek);
    if (index >= 0 && index < weeks.length - 1) {
      setSelectedWeek(weeks[index + 1]);
      setSelectedWeekSlot(weekTimeSlots[index + 1]);
    }
  };
  const previousWeek = () => {
    const index = weeks.indexOf(selectedWeek);
    if (index > 0 && index <= weeks.length) {
      setSelectedWeek(weeks[index - 1]);
      setSelectedWeekSlot(weekTimeSlots[index - 1]);
    }
  };

  const removeDuplicated = (data: any[]) => {
    return data.filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.DateTimeStart === value.DateTimeStart)
    );
  };

  const groupedTimeSlots = useMemo(() => {
    if (!selectedWeekSlot) {
      return [];
    }

    let groupedSlots: any = {};

    Object.entries(selectedWeekSlot).forEach(([key, value]: any, _) => {
      groupedSlots[key] = removeDuplicated(value);
    });

    return groupedSlots;
  }, [selectedWeekSlot]);

  useEffect(() => {
    trackScreen('DateTimeScreen');
  }, []);

  useEffect(() => {
    let apptType = undefined;
    if (apptNumber) {
      apptType = AppointmentType.Treatment;
    } else if (consultType) {
      apptType = consultType;
    } else if (searchParams.get('type')) {
      apptType = searchParams.get('type');
    } else {
      apptType = localStorage.getItem('appointmentType');
    }

    updateUser({
      service: apptType,
      location: {
        name: 'Rinse | Union St',
        address: '1763 Union St. San Francisco, CA 94123',
      },
    });

    if (apptNumber || consultType) {
      localStorage.setItem(LocalStorageKey.IsNewCustomer, 'false');
      fetchSpecificAvailability(apptType!, {
        appointmentNumber: apptNumber,
      });
    } else {
      // in the case the user refresh the page
      if (!isLoadingSlots) {
        fetchAvailability();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apptNumber]);

  useEffect(() => {
    setSelectedWeek(weeks[0]);
    setSelectedWeekSlot(weekTimeSlots[0]);
  }, [weeks, weekTimeSlots, isLoadingSlots]);

  const renderStates = () => {
    if (isLoadingSlots) {
      return (
        <div className="loaderWrapper">
          <p>Checking availability...</p>
        </div>
      );
    }

    if (weeks.length > 0 && !!selectedWeek) {
      return (
        <div>
          <div className={styles.weekSelector}>
            <img
              src={ChevronLeftIcon}
              onClick={previousWeek}
              alt="Previous Button"
            />
            <div>
              {selectedWeek.start.format('MMMM') ===
              selectedWeek.end.format('MMMM')
                ? `${selectedWeek.start.format(
                    'MMMM D'
                  )} - ${selectedWeek.end.format('D')}`
                : `${selectedWeek.start.format(
                    'MMMM D'
                  )} - ${selectedWeek.end.format('MMMM D')}`}
            </div>
            <img src={ChevronRightIcon} onClick={nextWeek} alt="Next Button" />
          </div>
          <div className={styles.timeSlotsCard}>
            {Object.entries(groupedTimeSlots).map(
              ([key, value]: any, index) => (
                <div key={index}>
                  <h1>{key}</h1>
                  <div className={styles.timeSlotGrid}>
                    {value.map((slot: any) => (
                      <DateCard
                        key={dateHashBuilder(slot)}
                        onSelect={() => setSelectedTime(dateHashBuilder(slot))}
                        isSelected={selectedTime === dateHashBuilder(slot)}
                        date={dayjs(slot.DateTimeStart).format('h:mm a')}
                      />
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div className="loaderWrapper">
          <p>No availability...</p>
        </div>
      );
    }
  };

  return (
    <Card
      progress={progress}
      title={`Choose a day and time`}
      back={back}
      showButton={!isLoadingSlots && weeks.length > 0}
      buttonText={
        canBookDirectly ? (schedulingAppointment ? 'Booking' : 'Book') : 'Next'
      }
      buttonOnClick={handleSubmit}
      buttonDisabled={schedulingAppointment}
    >
      <section className={styles.container}>{renderStates()}</section>
    </Card>
  );
}
