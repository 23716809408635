import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import isBetween from 'dayjs/plugin/isBetween';

import { PlannedAppointment, TimeSlot, User, WeekDuration } from '../types';
import { AppointmentType, ErrorType, LocalStorageKey } from '../utils/enums';
import { PatientError } from '../utils/errors';
import {
  createAppointment,
  createPatient,
  fetchTimeSlots,
  scheduleTreatmentAppointment,
} from '../api/od';

dayjs.extend(utc);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(isBetween);

interface UserContext {
  user: User;
  updateUser(data: any): void;
  fetchAvailability(): void;
  fetchSpecificAvailability(
    appointmentType: AppointmentType | string,
    extraData: any
  ): void;
  weeks: WeekDuration[];
  weekTimeSlots: any[];
  isLoadingSlots: boolean;
  handleUserSubmit: (user: User) => Promise<unknown>;
  schedulePlannedAppointment: (appt: PlannedAppointment) => Promise<unknown>;
  scheduleConsult: (user: User, patientId: string) => Promise<boolean>;
}

const userContext = createContext<UserContext>({} as UserContext);

interface Props {
  children: ReactNode;
}

export function UserProvider({ children }: Props) {
  const [user, setUser] = useState<User>({} as User);
  const [timeSlots, setTimeSlots] = useState({} as any);
  const [isLoadingSlots, setLoadingSlots] = useState(false);

  const weeks: WeekDuration[] = useMemo(() => {
    const createWeeks = (appointment?: AppointmentType) => {
      if (!appointment || !(appointment in timeSlots)) {
        return [];
      }

      const weekSlots = [] as any;
      Object.keys(timeSlots[appointment]).forEach((day) => {
        const weekStart = dayjs(day).startOf('week');
        if (
          !weekSlots.find((weekObj: any) => weekStart.isSame(weekObj.start))
        ) {
          weekSlots.push({
            start: weekStart,
            end: dayjs(day).endOf('week'),
          });
        }
      });

      return weekSlots;
    };

    return createWeeks(user.service);
  }, [timeSlots, user.service]);

  const weekTimeSlots: any[] = useMemo(() => {
    const createWeekTimeSlots = (appointment?: AppointmentType) => {
      if (!appointment || !(appointment in timeSlots)) {
        return [];
      }

      const availableTimeSlots = timeSlots[appointment];
      return weeks.map((week: WeekDuration) => {
        const data = Object.keys(availableTimeSlots).map((day: string) => {
          const slots = {} as any;
          availableTimeSlots[day].forEach((slot: TimeSlot) => {
            if (
              dayjs(slot.DateTimeStart).isBetween(
                dayjs(week.start),
                dayjs(week.end),
                'day',
                '[]'
              )
            ) {
              const weekDay = dayjs(slot.DateTimeStart).format('dddd, MMMM D');
              slots[weekDay] =
                slots[weekDay]?.length > 0 ? [...slots[weekDay], slot] : [slot];
            }
          });
          return slots;
        });

        return data
          .flat()
          .filter((obj) => Object.keys(obj).length > 0)
          .reduce((acc, key) => ({ ...acc, ...key }), {});
      });
    };

    return createWeekTimeSlots(user.service);
  }, [weeks, timeSlots, user.service]);

  async function handleUserSubmit(user: User) {
    const patient = await createPatient(user);
    if (
      patient?.result.error !== undefined ||
      patient?.result.id === undefined
    ) {
      throw new PatientError(
        patient?.result.error ??
          "Oops, an account already exist with your phone number but your data doesn't match. Please try again or text us at (415) 440-9000",
        ErrorType.PatientExist
      );
    }

    if (patient) {
      updateUser({
        patientId: patient.result.id,
        maskedId: patient.result.maskedId,
      });
      const appointment = await createAppointment(user, patient.result.id);
      if (!appointment.result.booked) {
        throw new PatientError(
          'Time slot is no longer available, please select a new one.',
          ErrorType.UnavailableTime
        );
      }
      return appointment as unknown;
    }

    // shouldn't get here never.
    throw new PatientError(
      'An error occurred while creating your appointment, please try again later.',
      ErrorType.Undefined
    );
  }

  function updateUser(data: any) {
    setUser((prevState) => ({ ...prevState, ...data }));
  }

  async function fetchAvailability() {
    setLoadingSlots(true);
    const [firstVisitTimes, emergencyTimes, orthoTimes] = await Promise.all([
      fetchTimeSlots(AppointmentType.FirstVisit),
      fetchTimeSlots(AppointmentType.Emergency),
      fetchTimeSlots(AppointmentType.Ortho),
    ]);
    setTimeSlots({
      [AppointmentType.FirstVisit]: firstVisitTimes,
      [AppointmentType.Emergency]: emergencyTimes ?? {},
      [AppointmentType.Ortho]: orthoTimes ?? {},
    });
    setLoadingSlots(false);
  }

  async function fetchSpecificAvailability(
    appointmentType: AppointmentType | string,
    extraData: any
  ) {
    localStorage.setItem(LocalStorageKey.AppointmentType, appointmentType);
    setLoadingSlots(true);
    const times = await fetchTimeSlots(appointmentType, extraData);
    setTimeSlots({
      [appointmentType]: times ?? {},
    });
    setLoadingSlots(false);
  }

  async function schedulePlannedAppointment(
    appt: PlannedAppointment
  ): Promise<boolean> {
    const res = await scheduleTreatmentAppointment(appt);
    return res.result.error === undefined;
  }

  async function scheduleConsult(
    user: User,
    patientId: string
  ): Promise<boolean> {
    const res = await createAppointment(user, patientId);
    if (!res.result.booked) {
      throw new PatientError(
        'Time slot is no longer available, please select a new one.',
        ErrorType.UnavailableTime
      );
    }

    return res.result.error === undefined;
  }

  return (
    <userContext.Provider
      value={{
        user,
        updateUser,
        weeks,
        weekTimeSlots,
        handleUserSubmit,
        isLoadingSlots,
        fetchAvailability,
        fetchSpecificAvailability,
        schedulePlannedAppointment,
        scheduleConsult,
      }}
    >
      {children}
    </userContext.Provider>
  );
}

export function useSchedule() {
  const context = useContext(userContext);
  return context;
}
