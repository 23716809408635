import styles from './styles.module.scss';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  text: string;
  colorScheme?: 'main' | 'secondary';
  textStyle?: any;
}

export function Button({
  text,
  colorScheme = 'main',
  textStyle,
  ...props
}: Props) {
  return (
    <button
      className={`${styles.container} ${
        colorScheme === 'main' ? styles.main : styles.secondary
      }`}
      {...props}
    >
      <div className={styles.text} style={textStyle}>
        {text}
      </div>
    </button>
  );
}
