import { useEffect } from 'react';
import { Card } from '../../components/Card';
import { LocationCard } from '../../components/LocationCard';
import { useSchedule } from '../../providers/main';
import { ISteps } from '../../types';
import { trackScreen } from '../../utils/logging/logging';
import styles from './styles.module.scss';

export function Location({ next, progress, back }: ISteps) {
  const { updateUser } = useSchedule();
  function handleSubmit() {
    updateUser({
      location: {
        name: 'Rinse | Union St',
        address: '1763 Union St. San Francisco, CA 94123',
      },
    });
    next();
  }

  useEffect(() => {
    trackScreen('LocationScreen');
  }, []);

  return (
    <Card
      progress={progress}
      title="Open in San Francisco"
      back={back}
      showButton
      buttonText="Next"
      buttonOnClick={handleSubmit}
    >
      <section className={styles.container}>
        <LocationCard
          name="Rinse | Union St"
          address="1763 Union St."
          city="San Francisco, CA 94123"
        />
      </section>
    </Card>
  );
}
