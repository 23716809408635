export enum LoggingEventTypes {
  ButtonClick,
  PageLoad,
  SelectVisit,
  Book,
  SelectDay,
  AddToCalendar,
  Error,
  FailedHttpCall,
  BookButtonURL, // used to track where the user came from
}