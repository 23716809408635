export const APPLE_STORE_APP_LINK =
  'https://apps.apple.com/us/app/rinse-dental/id1622461959';
export const TERMS_OF_USE = 'https://www.rinse.dental/support/terms-of-use';
export const PRIVACY_POLICY = 'https://www.rinse.dental/support/privacy-policy';

export type ScreenNames =
  | 'EmailScreen'
  | 'LocationScreen'
  | 'VisitTypeScreen'
  | 'DateTimeScreen'
  | 'ConfirmScreen'
  | 'SuccessScreen'
  | 'InsuranceCarrierSelectionScreen'
  | 'AddInsuranceScreen'
  | 'DoneScreen'
  | 'MediaScreen';

export const TIME_SLOTS = {
  '2022-09-01': [
    {
      DateTimeStart: '2022-09-01 09:00:00',
      DateTimeEnd: '2022-09-01 11:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
    {
      DateTimeStart: '2022-09-01 09:00:00',
      DateTimeEnd: '2022-09-01 11:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-01 11:00:00',
      DateTimeEnd: '2022-09-01 13:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
    {
      DateTimeStart: '2022-09-01 11:00:00',
      DateTimeEnd: '2022-09-01 13:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-01 14:00:00',
      DateTimeEnd: '2022-09-01 16:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
    {
      DateTimeStart: '2022-09-01 15:00:00',
      DateTimeEnd: '2022-09-01 17:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-01 16:00:00',
      DateTimeEnd: '2022-09-01 18:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
    {
      DateTimeStart: '2022-09-01 16:00:00',
      DateTimeEnd: '2022-09-01 18:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
  ],
  '2022-09-07': [
    {
      DateTimeStart: '2022-09-07 09:00:00',
      DateTimeEnd: '2022-09-07 11:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-07 11:00:00',
      DateTimeEnd: '2022-09-07 13:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
  ],
  '2022-09-08': [
    {
      DateTimeStart: '2022-09-08 09:00:00',
      DateTimeEnd: '2022-09-08 11:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
    {
      DateTimeStart: '2022-09-08 09:00:00',
      DateTimeEnd: '2022-09-08 11:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-08 11:00:00',
      DateTimeEnd: '2022-09-08 13:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
    {
      DateTimeStart: '2022-09-08 11:00:00',
      DateTimeEnd: '2022-09-08 13:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-08 15:00:00',
      DateTimeEnd: '2022-09-08 17:00:00',
      ProvNum: 5,
      OpNum: 2,
    },
    {
      DateTimeStart: '2022-09-08 16:00:00',
      DateTimeEnd: '2022-09-08 18:00:00',
      ProvNum: 4,
      OpNum: 1,
    },
  ],
};

export const EMAIL_REGEX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
