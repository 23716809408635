import DeltaDental from '../assets/insurance-logos/delta-dental.png';
import Guardian from '../assets/insurance-logos/guardian.png';
import Aetna from '../assets/insurance-logos/aetna.png';
import Cigna from '../assets/insurance-logos/cigna.png';
import MetLife from '../assets/insurance-logos/metlife.png';
import Principal from '../assets/insurance-logos/principal.png';

export const PRIMARY_INSURANCES_OPTIONS = [
  {
    id: 'DD_CALIFORNIA',
    name: 'Delta Dental of California',
    logo: DeltaDental,
  },
  {
    id: 'GUARDIAN',
    name: 'Guardian Life Insurance Co. of America',
    logo: Guardian,
  },
  {
    id: 'AETNA_DENTAL_PLANS',
    name: 'Aetna Denta Plans',
    logo: Aetna,
  },
  {
    id: 'CIGNA',
    name: 'CIGNA',
    logo: Cigna,
  },
  {
    id: 'METLIFE',
    name: 'MetLife',
    logo: MetLife,
  },
  {
    id: 'PRINCIPAL',
    name: 'Principal',
    logo: Principal,
  },
];
