import styles from './styles.module.scss';

interface Props {
  date: any;
  isSelected: boolean;
  onSelect?: () => void;
}

export function DateCard({ isSelected, onSelect, date }: Props) {
  return (
    <div
      className={`${styles.container} ${
        isSelected && styles.selectedContainer
      }`}
      onClick={onSelect}
    >
      <h2>{date}</h2>
    </div>
  );
}
