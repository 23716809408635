export enum AppointmentType {
  FirstVisit = 'firstVisit',
  Emergency = 'emergency',
  CheckUp = 'checkup',
  Treatment = 'treatment',
  Ortho = 'ortho',
}

export enum ErrorType {
  UnavailableTime = 'no_time',
  PatientExist = 'patient_exist',
  Undefined = '',
}

export enum LocalStorageKey {
  IsNewCustomer = 'isNewCustomer',
  AppointmentType = 'appointmentType',
}
