import { useParams, useSearchParams } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { Card } from '../../components/Card';
import styles from './styles.module.scss';

import { useEffect, useState } from 'react';
import { trackScreen } from '../../utils/logging/logging';
import { getMediaFiles } from '../../api/od';
import dayjs from 'dayjs';
import { Visit } from '../../types';
import { MediaLanding } from './MediaLanding';
import { MediaList } from './MediaList';
import arrow_back from '../../assets/arrow_back_ios.png';

// type SearchParams = 'date' | 'type' | 'index' | 'view';

export const MediaScreen = () => {
  const { identifier } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const dateSearchParam = searchParams.get('date') ?? undefined;
  const typeSearchParam = searchParams.get('type') ?? undefined;
  const indexSearchParam = searchParams.get('index') ?? undefined;
  const viewSearchParam = searchParams.get('view') ?? undefined;
  const isFromApp = viewSearchParam === 'app';

  const [loading, setLoading] = useState(true);
  const [patientName, setPatientName] = useState<string | undefined>(undefined);
  const [appointments, setAppointments] = useState<Visit[]>([]);

  const [selectedAppt, setselectedAppt] = useState<Visit | undefined>(
    undefined
  );
  const [title, setTitle] = useState('Your Images');
  const [subtitle, setSubtitle] = useState<string | undefined>(undefined);

  const goBack = () => {
    if (indexSearchParam === undefined) {
      if (selectedAppt) {
        setselectedAppt(undefined);
        setSearchParams({});
      }
    } else {
      setSearchParams({
        view: viewSearchParam ?? '',
        date: dateSearchParam ?? '',
        type: typeSearchParam ?? '',
      });
    }
  };

  useEffect(() => {
    if (identifier) {
      getMediaFiles({ maskedId: identifier }).then(res => {
        if (res.status === 200) {
          res.json().then((data: any) => {
            setAppointments(data.result.appointments);
            setPatientName(data.result.patient.fullName);
            if (data.result.patient) {
              setTitle(`${data.result.patient?.fullName}'s Images`);
            }

            if (
              !data.result.appointments ||
              data.result.appointments.length === 0
            ) {
              setSubtitle("You haven't taken any images yet.");
            }

            if (dateSearchParam) {
              const matched = data.result?.appointments?.find((appt: any) => {
                return dayjs(appt.AptDateTime).isSame(dayjs(dateSearchParam));
              });
              setselectedAppt(matched);
            }

            setLoading(false);
          });
        }
      });
    }
    trackScreen('MediaScreen');
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (selectedAppt) {
      searchParams.append('date', selectedAppt.AptDateTime);
      setSearchParams(searchParams);
      setTitle(selectedAppt.title);
      setSubtitle(
        dayjs(selectedAppt.AptDateTime).format('dddd h a PT, MMMM D, YYYY')
      );
    } else {
      setTitle(`${patientName}'s Images`);
      setSubtitle(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAppt, loading]);

  useEffect(() => {
    if (indexSearchParam === undefined && selectedAppt) {
      setTitle(selectedAppt.title);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexSearchParam]);

  return loading ? (
    <Card
      progress={100}
      mainContentStyle={{ overflowY: 'unset' }}
      displayHeader={!isFromApp}>
      <Spinner animation="border" className={styles.spinner} />
    </Card>
  ) : (
    <Card
      displayHeader={!isFromApp}
      progress={100}
      back={selectedAppt ? goBack : undefined}
      mainContentStyle={{
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '20px',
        marginRight: '20px',
        textAlign: 'flex-start !important',

        width: searchParams.get('index') !== undefined ? 'unset' : '320px',
      }}>
      <div>
        <div className={styles.titleWrapper}>
          {/* Back Button */}
          {isFromApp && indexSearchParam !== undefined && (
            <div
              className={styles.backText}
              onClick={() => {
                goBack();
              }}>
              <img
                src={arrow_back}
                alt="back"
                style={{
                  width: '2%',
                  height: '2%',
                  marginTop: '4px',
                  marginRight: '4px',
                }}
              />
              Back
            </div>
          )}
          {/* Title */}
          <div className={styles.title}>{title}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>

        {!selectedAppt ? (
          <MediaLanding
            appointments={appointments}
            onSelect={(appt: Visit) => {
              setselectedAppt(appt);
            }}
          />
        ) : (
          <MediaList
            appt={selectedAppt}
            updateTitle={title => {
              setTitle(title);
              setSubtitle(undefined);
            }}
          />
        )}
      </div>
    </Card>
  );
};
