import { ErrorType } from "./enums";

export class PatientError extends Error {
  errorType: ErrorType;

  constructor(message: string, errorType: ErrorType) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);  // restore prototype chain
    this.name = 'AppError';
    this.errorType = errorType;
  }
}