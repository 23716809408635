import Modal from 'react-modal';
import { GenericFunction } from '../../../types';
import { Button } from '../../Button';
import styles from './styles.module.scss';

Modal.setAppElement('#root');

interface Props extends Modal.Props {
  onClick?: GenericFunction;
}

export function SlotAlreadyBookedModal({ onClick, ...props }: Props) {
  return (
    <Modal
      {...props}
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
      className={styles.container}
    >
      <h2>Uh oh! That slot’s taken</h2>
      <p className={styles.message}>
        It looks like someone just booked that slot. We could not confirm your
        visit. Please try again.
      </p>

      <Button onClick={onClick} text="Select Another Time" />
    </Modal>
  );
}
