import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './styles.module.scss';

import { MediaInfoType, Visit } from '../../types';
import foward_right_arrow from '../../assets/arrow_forward_ios.png';
import { MediaListDetails } from './MediaListDetails';

export const MediaList = ({
  appt,

  updateTitle,
}: {
  appt: Visit;
  updateTitle: (title: string) => any;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const indexParam = searchParams.get('index') ?? undefined;

  const [indexDetails, setIndexDetails] = useState<number | undefined>(
    indexParam !== undefined && !isNaN(+indexParam) ? +indexParam : undefined
  );
  const [selectedElementKey, setSelectedElementKey] = useState<
    string | undefined
  >(searchParams.get('type') ?? undefined);

  const [openSections, setOpenSections] = useState<{ [key: string]: boolean }>(
    {}
  );

  const groupInfo = (
    files: MediaInfoType[]
  ): { [key: string]: MediaInfoType[] } => {
    let res: { [key: string]: MediaInfoType[] } = {};
    files.forEach((f) => {
      const modality = f.modality?.LongName ?? 'Other';
      if (!(modality in res)) {
        res[modality] = [];
      }
      res[modality].push(f);
    });

    return res;
  };

  const groupedByModalities: {
    [key: string]: MediaInfoType[];
  } = groupInfo(appt.images ?? []);

  useEffect(() => {
    if (indexDetails !== undefined) {
      searchParams.append('index', indexDetails.toString());
      setSearchParams(searchParams);
      updateTitle(`${selectedElementKey} ${indexDetails + 1}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexDetails]);

  useEffect(() => {
    const value = searchParams.get('index');
    setIndexDetails(value !== null ? +value : undefined);
  }, [searchParams]);

  return indexDetails !== undefined ? (
    <MediaListDetails
      index={indexDetails}
      files={groupedByModalities[selectedElementKey!]}
      onIndexChange={(newIndex) =>
        updateTitle(`${selectedElementKey} ${newIndex + 1}`)
      }
    />
  ) : (
    <div className={styles.container}>
      {Object.keys(groupedByModalities)
        .sort()
        .map((key, groupByIndex) => {
          return (
            <div key={key}>
              <hr className={styles.divider}></hr>
              <details
                key={key}
                onToggle={(e) => {
                  const isOpen = (e.currentTarget as HTMLDetailsElement).open;
                  openSections[groupByIndex] = isOpen;

                  setOpenSections({ ...openSections });
                  if (isOpen) {
                    searchParams.append('type', key);
                  } else {
                    searchParams.delete('type');
                  }
                  setSearchParams(searchParams);
                }}
              >
                <summary>
                  <div>{key}</div>
                  <img
                    className={
                      styles.arrow +
                      ' ' +
                      // rotate right arrow
                      (groupByIndex in openSections &&
                      openSections[groupByIndex]
                        ? styles.rotateRightArrow
                        : '')
                    }
                    src={foward_right_arrow}
                    alt=""
                  />
                </summary>
                <div className={styles.imagesContainer}>
                  {groupedByModalities[key].map((f, index) => (
                    <div
                      key={index}
                      className={styles.imgContainer}
                      onClick={() => {
                        setIndexDetails(index);
                        setSelectedElementKey(key);
                      }}
                    >
                      <img
                        key={f.url}
                        className={styles.media}
                        src={f.url}
                        alt=""
                      />
                      <div className={styles.imgDescription}>
                        <label style={{ color: '#333F4C' }}>{`${
                          f.modality?.ShortName
                        } ${index + 1}`}</label>
                        {f.previewFileSize && (
                          <label style={{ color: '#7F8790' }}>
                            {((f.fileSize ?? 0) / 1000000).toFixed(2)} MB
                          </label>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </details>
            </div>
          );
        })}
      <hr className={styles.divider}></hr>
    </div>
  );
};
