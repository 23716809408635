export const ROUTES = {
  email: 'email',
  selectLocation: 'location',
  selectVisitType: 'selectVisitType',
  selectDates: 'selectDates',
  confirm: 'confirm',
  success: 'success',
  payment: 'payment',
  allDone: 'done',
  insuranceVerfication: 'insurance-verification',
  insuranceAccept: 'insurance-accept',
  insuranceDontKnow: 'insurance-dont-know',
  insuranceReject: 'insurance-reject',
  insuranceCarrierSelection: 'insuranceCarrierSelection',
  addInsurance: 'addInsurance',
  nextVisit: 'nextVisit',
  done: 'done',
};
