import dayjs from 'dayjs';
import { Patient } from '../types';
import { getRandomIntInclusive } from "../utils/random";

export const mockSchedule = (daysFoward: number) => {
  let res: { [key: string]: any[] } = {}

  let current = dayjs();

  for (let index = 0; index < daysFoward; index++) {
    let slots = [];

    // start at 8 am
    let morningStartDate = current.set("hour", 8).set('minute', 0).set('second', 0)

    // generating 20 slots
    for (let j = 0; j < 20; j++) {
      slots.push({
        "DateTimeStart": morningStartDate.format("YYYY-MM-DD HH:mm:ss"),
        "DateTimeEnd": morningStartDate.add(75, 'minute').format("YYYY-MM-DD HH:mm:ss"),
        "ProvNum": getRandomIntInclusive(1, 6),
        "OpNum": getRandomIntInclusive(1, 5)
      })
      morningStartDate = morningStartDate.add(15, 'minute')
    }
    res[current.format("YYYY-MM-DD")] = slots;
    current = current.add(1, 'day');
  }

  return res
}

export const mockPatient = (): { result: Patient } => {
  return { "result": { "id": getRandomIntInclusive(1, 1000).toString(), resourceType: "Patient" } }
}

export const mockAppointment = () => {
  return {
    "result": {
      "booked": true
    }
  }
}