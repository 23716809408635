import styles from './styles.module.scss';
import { Visit } from '../../types';
import foward_right_arrow from '../../assets/arrow_forward_ios.png';
import dayjs from 'dayjs';

export const MediaLanding = ({
  appointments,
  onSelect,
}: {
  appointments: Visit[];
  onSelect: (appt: Visit) => any;
}) => {
  const years = Array.from(
    new Set(appointments.map((x) => dayjs(x.AptDateTime).year())).values()
  ).sort((a, b) => b - a);

  return (
    <div className={styles.container}>
      {years.map((y) => (
        <div key={y} className={styles.yearContainer}>
          <div className={styles.yearHeader}>{y}</div>
          <hr className={styles.divider}></hr>
          {appointments
            .filter((x) => dayjs(x.AptDateTime).year() === y)
            .sort((a, b) =>
              dayjs(a.AptDateTime).isBefore(dayjs(b.AptDateTime))
                ? 1
                : a.AptDateTime === b.AptDateTime
                ? 0
                : -1
            )
            .map((appt) => (
              <div key={appt.AptNum} onClick={() => onSelect(appt)}>
                <div className={styles.line + ' ' + styles.lineText}>
                  <div>
                    {`${appt.title}, ${dayjs(appt.AptDateTime).format(
                      'MMM D'
                    )}, ${appt.images?.length} image${
                      appt.images?.length ?? 0 > 1 ? 's' : ''
                    }`}
                  </div>
                  <img
                    className={styles.arrow}
                    src={foward_right_arrow}
                    alt=""
                    style={{ color: '#333F4C' }}
                  />
                </div>
                <hr className={styles.divider}></hr>
              </div>
            ))}
        </div>
      ))}
    </div>
  );
};
