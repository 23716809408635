import { Controller, UseFormReturn } from 'react-hook-form';
import { get as _get } from 'lodash';
import styles from './styles.module.scss';

interface Props {
  form: UseFormReturn<any, any>;
  name: string;
  label?: string;
  validation?: any;
  autofocus?: boolean;
  placeholder?: string;
}

export function AgreementField({
  form,
  name,
  label,
  validation,
  autofocus,
}: Props) {
  const error =
    form.formState.errors[name] || _get(form.formState.errors, name.split('.'));

  return (
    <Controller
      name={name}
      control={form.control}
      rules={validation}
      render={({ field }) => (
        <div className={styles.container}>
          {label && <label>{label}</label>}
          <div className={styles.options}>
            <label>
              <input
                ref={field.ref}
                name={field.name}
                type="radio"
                className={styles.radio}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => {
                  field.onChange('yes');
                }}
                autoFocus={!!autofocus}
              />
              Yes
            </label>
            <label>
              <input
                ref={field.ref}
                name={field.name}
                type="radio"
                className={styles.radio}
                value={field.value}
                onBlur={field.onBlur}
                onChange={(e) => {
                  field.onChange('no');
                }}
                autoFocus={!!autofocus}
              />
              No
            </label>
          </div>

          {error && (
            <div className={styles.errorMessage}>
              {error?.message || 'Invalid input'}
            </div>
          )}
        </div>
      )}
    />
  );
}
