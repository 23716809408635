import { Card } from '../../components/Card';
import styles from './styles.module.scss';
import DoneFlowInsured from '../../assets/done-flow-insured.svg';
import DoneFlowUnInsured from '../../assets/done-flow-uninsured.svg';
import YoureAllSet from '../../assets/youre-all-set.png';
import { useSchedule } from '../../providers/main';
import { useEffect } from 'react';
import { trackEvent, trackScreen } from '../../utils/logging/logging';
import dayjs from 'dayjs';
import { APPLE_STORE_APP_LINK } from '../../utils/constants';
import {
  formatAppointmentName,
  formatGoogleCalendarLink,
} from '../../utils/format';
import { AppointmentType, LocalStorageKey } from '../../utils/enums';
import { LoggingEventTypes } from '../../utils/logging/event';

export const DoneScreen = () => {
  const { user } = useSchedule();
  const isFirstTimeUser = localStorage.getItem(LocalStorageKey.IsNewCustomer);

  useEffect(() => {
    trackScreen('DoneScreen');
    window.scrollTo(0, 0);
  }, []);

  return (
    <Card
      progress={100}
      showButton
      buttonText="🗓 Add to Calendar"
      buttonColorScheme="secondary"
      buttonOnClick={() => {
        window.location.href = formatGoogleCalendarLink(user);
        trackEvent(LoggingEventTypes.AddToCalendar);
      }}
      showSecondaryButton
      secondaryButtonText="📱 Download iOS App"
      secondaryButtonOnClick={() => {
        window.location.href = APPLE_STORE_APP_LINK;
      }}
    >
      <div className={styles.container}>
        <div className={styles.emoji}>🥳</div>
        <img
          className={styles.youreAllSet}
          src={YoureAllSet}
          alt="youre-all-set"
        />
        {isFirstTimeUser === 'true' && (
          <img
            className={styles.flow}
            src={user.hasDentalInsurance ? DoneFlowInsured : DoneFlowUnInsured}
            alt="done-flow"
          />
        )}
        <div className={styles.confirmationText}>
          You're all set! We’ll see you on <br />
          <span className={styles.bold}>
            {dayjs(user.timeslot?.DateTimeStart).format('dddd, MMMM D')} at{' '}
            {dayjs(user.timeslot?.DateTimeStart).format('h:mm A')}
          </span>{' '}
          <br />
          for your{' '}
          <span className={styles.bold}>
            {formatAppointmentName(user.service as AppointmentType)}
          </span>
          .
        </div>
      </div>
    </Card>
  );
};
