import styles from './styles.module.scss';
import rightArrowIcon from '../../assets/right-arrow.svg';
interface Props {
  title: string;
  description?: string;
  price?: string;
  isEmergency?: boolean;
  onSelect?: () => void;
}

export function SelectCard({
  isEmergency,
  onSelect,
  title,
  description,
  price,
}: Props) {
  return (
    <div
      className={`${styles.container} ${isEmergency && styles.emergency}`}
      onClick={onSelect}
    >
      <div className={styles.content}>
        <h2 className={isEmergency ? `${styles.emergency}` : ''}>{title}</h2>
        <p className={styles.description}>{description}</p>
        <p className={styles.price}>
          <div>{price ?? 'Free'}</div>
          {price && <div className={styles.priceVerticalSeparator}>|</div>}
          {price && <div>Free with most insurance</div>}
        </p>
      </div>
      <img src={rightArrowIcon} alt="select card" />
    </div>
  );
}
