import { SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '../../components/Card';
import { SelectCard } from '../../components/SelectCard';
import { useSchedule } from '../../providers/main';
import { ISteps } from '../../types';
import { AppointmentType, LocalStorageKey } from '../../utils/enums';
import { LoggingEventTypes } from '../../utils/logging/event';
import { trackEvent, trackScreen } from '../../utils/logging/logging';
import styles from './styles.module.scss';
import Dialog from '@mui/material/Dialog';
import { Button } from '../../components/Button';

export const VisitType = ({ next, progress, back }: ISteps) => {
  const { updateUser, fetchAvailability } = useSchedule();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isInPain, setIsInPain] = useState<boolean>(true);
  const [reasonText, setReasonText] = useState<string>('');

  const { referrer } = useParams();

  const handleClick = (appointmentType: AppointmentType) => {
    localStorage.setItem(LocalStorageKey.AppointmentType, appointmentType);
    localStorage.setItem(LocalStorageKey.IsNewCustomer, 'true');

    if (appointmentType === AppointmentType.Emergency) {
      setIsModalOpen(true);
    } else {
      trackEvent(LoggingEventTypes.SelectVisit, {
        visitType: appointmentType.toString(),
      });
      updateUser({ service: appointmentType });
      next();
    }
  };

  useEffect(() => {
    fetchAvailability();
    trackScreen('VisitTypeScreen');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (referrer) {
      updateUser({ referredBy: referrer });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrer]);

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e: { target: { value: SetStateAction<string> } }) => {
    setReasonText(e.target.value);
  };

  const handleSubmit = () => {
    trackEvent(LoggingEventTypes.SelectVisit, {
      visitType: AppointmentType.Emergency.toString(),
    });
    updateUser({
      service: AppointmentType.Emergency,
      isInPain: isInPain,
      reasonText: reasonText,
    });
    next();
  };

  return (
    <>
      <Card progress={progress} title="How can we help you?">
        <section className={styles.container}>
          <SelectCard
            onSelect={() => handleClick(AppointmentType.FirstVisit)}
            title="Dental Checkup"
            description="Get started with a cleaning, x-rays, and an exam in 90 min."
            price="$350"
          />
          <SelectCard
            onSelect={() => handleClick(AppointmentType.Emergency)}
            isEmergency
            title="Urgent Care"
            description="Let's see you asap."
            price="$100"
          />
          <SelectCard
            onSelect={() => handleClick(AppointmentType.Ortho)}
            title="Ortho Consult"
            price={undefined}
            description="15 min Clear Aligner consult."
          />
        </section>
      </Card>
      <Dialog
        open={isModalOpen}
        onClose={handleClose}
        PaperProps={{
          style: { borderRadius: '24px' },
        }}
      >
        <div className={styles.modal}>
          <div className={styles.inPain}>
            <div>Are you in pain?</div>
            <div className={styles.options}>
              <label>
                <input
                  name="yes"
                  type="radio"
                  className={styles.radio}
                  onChange={() => setIsInPain(true)}
                  checked={isInPain}
                />
                Yes
              </label>
              <label>
                <input
                  name="no"
                  type="radio"
                  className={styles.radio}
                  onChange={() => setIsInPain(false)}
                  checked={!isInPain}
                />
                No
              </label>
            </div>
          </div>

          <div className={styles.reason}>
            <textarea
              className={styles.textArea}
              value={reasonText}
              onChange={handleChange}
              placeholder="Your reason for urgent care? (optional)"
              rows={5}
              style={{ width: '279px' }}
            />
          </div>

          <div className={styles.buttonWrapper}>
            <Button
              style={{ boxShadow: 'none' }}
              text="Continue"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
