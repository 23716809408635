// CustomDateInput.tsx
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import styles from './styles.module.scss';

interface CustomDateInputProps {
  selectedDate: Date | null;
  onDateChange: (date: Date | null) => void;
}

const CustomDateInput: React.FC<CustomDateInputProps> = ({
  selectedDate,
  onDateChange,
}) => {
  const [inputValue, setInputValue] = useState('');

  const handleBlur = () => {
    const parsedDate = dayjs(inputValue, 'MM/DD/YYYY').toDate();
    if (dayjs(parsedDate).isValid()) {
      onDateChange(parsedDate);
    } else {
      setInputValue('');
      onDateChange(null);
    }
  };

  function formatDate(input: string): string {
    // Keep only numbers and dashes
    if (selectedDate) {
      return dayjs(selectedDate).format('MM/DD/YYYY');
    }
    const formattedValue = input.replace(/[^0-9]/g, '');
    if (formattedValue.length > 4) {
      return (
        formattedValue.slice(0, 2) +
        '/' +
        formattedValue.slice(2, 4) +
        '/' +
        formattedValue.slice(4, 8)
      );
    } else if (formattedValue.length > 2) {
      return formattedValue.slice(0, 2) + '/' + formattedValue.slice(2, 4);
    } else {
      return formattedValue;
    }
  }

  return (
    <DatePicker
      portalId="root-portal"
      selected={selectedDate}
      onChange={(date: Date | null) => {
        onDateChange(date);
        setInputValue(dayjs(date).format('MM/DD/YYYY'));
      }}
      placeholderText="MM/DD/YYYY"
      onChangeRaw={event => {
        setInputValue(event.target.value);
      }}
      dateFormat="MM/dd/yyyy"
      customInput={
        <input type="text" value={inputValue} className={styles.datePicker} />
      }
      value={formatDate(inputValue)}
      onBlur={handleBlur}
    />
  );
};

export default CustomDateInput;
