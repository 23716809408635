import { useState } from 'react';
import {
  Elements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import toast from 'react-hot-toast';
import { Button } from '../../components/Button';
import { loadStripe } from '@stripe/stripe-js';
import { Card } from '../../components/Card';
import './styles.css';
import { createStripeSetupIntent } from '../../api/od';
import { ISteps } from '../../types';
import { useSchedule } from '../../providers/main';

const stripePromise = loadStripe(
  'pk_test_51IvpS3FepmdPNzizPGQT4lpeKww9LV9bzVDK6vHFoxutIAHFyQ0vTOTcmnuN2uoE5pAMpmGlkRCg4qAA1neLmAZh00zDKn07i0'
);

const handleBlur = () => {};
const handleChange = (change: any) => {};
const handleFocus = () => {};
const handleReady = () => {};

const stripeOptions = {
  style: {
    base: {
      color: '#153260',
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const CardForm = ({ onSuccess }: { onSuccess: () => any }) => {
  const [savingPayment, setSavingPayment] = useState(false);

  const { user } = useSchedule();

  const [error, setError] = useState<string | undefined>('');
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (ev: any) => {
    toast.loading('Verifying ...');
    ev.preventDefault();

    setError('');

    try {
      const [intentResponse, payload] = await Promise.all([
        createStripeSetupIntent({
          maskedId: user.maskedId,
          patNum: user.patientId,
        }),
        stripe!.createPaymentMethod({
          type: 'card',
          card: elements!.getElement(CardNumberElement)!,
        }),
      ]);

      if (payload.error) {
        setError(payload.error?.message);
      } else {
        const intentResult = await intentResponse.json();

        if (!intentResult.result?.intentSecret) {
          setError('Unexpected error');
        } else {
          await stripe!.confirmCardSetup(intentResult.result.intentSecret, {
            payment_method: {
              card: elements!.getElement(CardNumberElement)!,
            },
          });
          onSuccess();
        }
      }
    } catch (e) {
      console.log(e);
    }

    toast.dismiss();
    setSavingPayment(false);
  };

  return (
    <form>
      <div>
        <div className="cardElement">
          <label>Card Number</label>
          <CardNumberElement
            onChange={handleChange}
            onFocus={handleFocus}
            onReady={handleReady}
            options={{
              ...stripeOptions,
              placeholder: '1234 1234 1234 1234',
            }}
          />
        </div>
        <div className="cardExtra">
          <div className="cardExtraElement">
            <label>Expiration Date</label>
            <CardExpiryElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              options={stripeOptions}
            />
          </div>
          <div className="cardExtraElement">
            <label>CVC</label>
            <CardCvcElement
              onBlur={handleBlur}
              onChange={handleChange}
              onFocus={handleFocus}
              onReady={handleReady}
              options={stripeOptions}
            />
          </div>
        </div>
        <div className="error">{error}</div>
      </div>
      <Button
        style={{ marginTop: '64px' }}
        type="submit"
        text={'Add a payment method'}
        onClick={(e) => {
          setSavingPayment(true);
          handleSubmit(e);
        }}
        disabled={savingPayment}
      />
    </form>
  );
};

export const PaymentScreen = ({ next, progress, back }: ISteps) => {
  return (
    <Card
      progress={progress}
      title="Add a payment method"
      subtitle="Your card will not be charged today and will only be used for
                deductibles, copayments, or services not covered by insurance."
      back={back}
      notificationMessage={'Your dental plan has been added.'}
      displayNotification={true}
    >
      <div className="mainContent">
        {/* stripe form */}
        <Elements stripe={stripePromise}>
          <CardForm
            onSuccess={() => {
              next();
            }}
          />
        </Elements>
        {/* submit button */}
      </div>
    </Card>
  );
};
