import { HTMLInputTypeAttribute, useState } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { get as _get } from 'lodash';
import styles from './styles.module.scss';
import calendarIcon from '../../../assets/calendar-gray.svg';

interface Props {
  form: UseFormReturn<any, any>;
  name: string;
  icon?: string;
  validation?: any;
  autofocus?: boolean;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  value?: string;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'numeric'
    | 'decimal';
}
export function TextField({
  form,
  name,
  type,
  placeholder,
  icon,
  validation,
  autofocus,
  className,
  inputClassName,
  inputMode,
  value,
}: Props) {
  // small hack to render the calendarIcon when the input is of type 'date' and the element is unfocused.
  // for some reason, by default the input type 'date' doesn't you allow to change the placeholder text.
  // we effectively change the inputType to 'text' initially, and use the onFocus handler to change it to 'date'
  const [inputType, setInputType] = useState<string | undefined>(
    type === 'date' ? 'text' : type
  );
  const error =
    form.formState.errors[name] || _get(form.formState.errors, name.split('.'));

  return (
    <Controller
      name={name}
      control={form.control}
      rules={validation}
      defaultValue={value || ''}
      render={({ field }) => {
        return (
          <div className={`${styles.container} ${className && className}`}>
            <div className={styles.inputContainer}>
              <input
                ref={field.ref}
                name={field.name}
                inputMode={inputMode}
                type={type === 'date' || type === undefined ? 'text' : type}
                className={`${styles.input} ${
                  inputClassName && inputClassName
                }`}
                placeholder={placeholder}
                value={field.value}
                onBlur={e => {
                  field.onBlur();
                  if (type === 'date') {
                    setInputType('text');
                    return (e.target.type = 'text');
                  }
                }}
                onChange={field.onChange}
                autoFocus={!!autofocus}
                onFocus={e => {
                  if (type === 'date') {
                    setInputType('date');
                    return (e.target.type = 'date');
                  }
                }}
              />
              {icon && <img src={icon} alt="input icon" />}
            </div>

            {type === 'date' && inputType === 'text' && calendarIcon && (
              <img
                className={styles.calendarIcon}
                src={calendarIcon}
                alt={''}
              />
            )}
            {error && (
              <div className={styles.errorMessage}>
                {error?.message || 'Invalid input'}
              </div>
            )}
          </div>
        );
      }}
    />
  );
}
