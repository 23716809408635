import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { Card } from '../../components/Card';
import { TextField } from '../../components/form/TextField';
import styles from './styles.module.scss';
import { createCustomerInsurance } from '../../api/od';
import { AgreementField } from '../../components/form/AgreementField';
import { useSchedule } from '../../providers/main';
import { ISteps } from '../../types';
import { trackScreen } from '../../utils/logging/logging';
import LockIcon from '../../assets/lock.svg';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import CustomDateInput from '../../components/DateTimeInput';

export const InsuranceHelpScreen = ({ next, progress, back }: ISteps) => {
  const { user } = useSchedule();
  const { phoneNumber, patientId } = user;

  const [error, setErrorMessage] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const form = useForm({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      dob: '',
      memberId: '',
      insuranceCarrierId: user.insuranceCarrierId,
      insuranceCarrierName: user.insuranceCarrierName,
    },
  });

  const { handleSubmit } = form;

  const onSubmit = async (data: any) => {
    toast.loading('Verifying ...');
    setErrorMessage('');
    setLoadingRequest(true);
    let user = {
      ...data,
      dob: dayjs(selectedDate).format('YYYY-MM-DD'),
      hasDentalInsurance: true,
    };
    setErrorMessage('');

    try {
      const res = await createCustomerInsurance({
        patient: {
          membership: user.memberId,
          firstname: user.firstName!,
          lastname: user.lastName!,
          birthdate: user.dob!,
        },
        policyHolder: {
          membership: '',
          firstname: data.isSubscriber === 'yes' ? user.firstName! : '',
          lastname: data.isSubscriber === 'yes' ? user.lastName! : '',
          birthdate: data.isSubscriber === 'yes' ? user.dob! : '',
        },
        relationship: {
          id: '',
          name: '',
        },
        payer: {
          id: user.insuranceCarrierId!,
          name: user.insuranceCarrierName!,
          source: 'ONEDERFUL',
        },
        phoneNumber: phoneNumber,
        patientId: patientId as string,
      });
      if (res.ok) {
        next();
      } else {
        setErrorMessage(`Error doing request. ${res.statusText}`);
      }
      toast.dismiss();
      setLoadingRequest(false);
    } catch (error) {
      setErrorMessage(`Error verifying insurance. ${error}`);
      toast.dismiss();
      setLoadingRequest(false);
    }
  };

  useEffect(() => {
    trackScreen('AddInsuranceScreen');
  }, []);

  return (
    <Card
      progress={progress}
      title="Let's find your plan"
      back={() => {
        setLoadingRequest(false);
        setErrorMessage('');
        back();
      }}
      showButton
      buttonText="Add Insurance"
      buttonDisabled={loadingRequest}
      buttonOnClick={handleSubmit(onSubmit)}>
      <form className={styles.formContainer}>
        <fieldset disabled={loadingRequest}>
          <div className={styles.label}>Legal First Name</div>
          <TextField
            form={form}
            name="firstName"
            placeholder="Legal First Name"
            value={user.firstName}
            validation={{
              required: { value: true, message: 'Enter your first name' },
            }}
          />

          <div className={styles.label}>Legal Last Name</div>
          <TextField
            form={form}
            name="lastName"
            placeholder="Legal Last Name"
            value={user.lastName}
            validation={{
              required: { value: true, message: 'Enter your last name' },
            }}
          />

          <div className={styles.label}>Member ID or SSN</div>
          <div className={styles.labelDescription}>
            Most dental plans can be found using your Social Security Number
            (SSN).
          </div>

          <TextField
            form={form}
            name="memberId"
            placeholder="Member ID or SSN"
            icon={LockIcon}
            validation={{
              required: { value: true, message: 'Membership id' },
            }}
          />

          <div className={styles.label}>Birthday</div>
          <CustomDateInput
            selectedDate={selectedDate}
            onDateChange={date => setSelectedDate(date)}
          />

          <AgreementField
            label="Are you the primary on this dental plan?"
            name="isSubscriber"
            form={form}
            validation={{
              required: { value: true, message: 'Select a valid option' },
            }}
          />

          {!loadingRequest && error.length > 0 && (
            <div className={styles.error}>{error}</div>
          )}
        </fieldset>
      </form>
    </Card>
  );
};
