import { ReactNode, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import styles from './styles.module.scss';
import LeftArrowIcon from '../../assets/left-arrow.svg';
import logoIcon from '../../assets/logo.svg';
import smsIcon from '../../assets/sms.svg';
import { GenericFunction } from '../../types';
import CheckIcon from '../../assets/check.svg';
import CloseIcon from '../../assets/close.svg';
import { Button } from '../Button';

interface Props {
  title?: string;
  subtitle?: string;
  children: ReactNode;
  progress: number;
  back?: GenericFunction;
  removeBackButton?: boolean;
  displayNotification?: boolean;
  displayHeader?: boolean;
  notificationMessage?: string | JSX.Element;
  mainContentStyle?: any;
  showButton?: boolean;
  buttonText?: string;
  buttonColorScheme?: 'main' | 'secondary';
  buttonDisabled?: boolean;
  showSecondaryButton?: boolean;
  secondaryButtonText?: string;
  secondaryButtonColorScheme?: 'main' | 'secondary';
  secondaryButtonDisabled?: boolean;
  buttonOnClick?: (() => void) | ((e: any) => void);
  secondaryButtonOnClick?: (() => void) | ((e: any) => void);
}

export function Card({
  title,
  subtitle,
  children,
  progress,
  back,
  removeBackButton,
  displayNotification,
  displayHeader = true,
  notificationMessage,
  mainContentStyle,
  showButton,
  buttonColorScheme = 'main',
  buttonText,
  buttonDisabled,
  showSecondaryButton,
  secondaryButtonText,
  secondaryButtonColorScheme = 'secondary',
  secondaryButtonDisabled,
  buttonOnClick,
  secondaryButtonOnClick,
}: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [showNotificaction, setShowNotification] =
    useState(displayNotification);

  return (
    <section className={styles.container}>
      {displayHeader && (
        <div className={styles.sticky}>
          <div className={styles.menu}>
            <span className={styles.iconButton}>
              {back && (
                <img
                  className={styles.backButton}
                  onClick={back}
                  src={LeftArrowIcon}
                  alt="Go Back Icon"
                  style={{ display: !removeBackButton ? 'inline' : 'none' }}
                />
              )}
            </span>

            <img
              className={styles.logo}
              src={logoIcon}
              alt="Rinse logo in gradient from blue to pink"
            />

            {isMobile ? (
              <a href="sms:+14154409000" className={styles.smsIcon}>
                <img src={smsIcon} alt="Text box" />
              </a>
            ) : (
              <div className={styles.phoneNumber}>
                <a href="sms:+14154409000" className={styles.phoneNumber}>
                  (415) 440-9000
                </a>
              </div>
            )}
          </div>

          <div className={styles.progressIndicatorWrapper}>
            <span
              style={{ width: `${progress}%` }}
              className={styles.progressIndicator}
            />
          </div>
        </div>
      )}

      <div className={styles.content}>
        {title && (
          <div
            className={styles.titleWrapper}
            style={{ width: '320px', ...mainContentStyle }}
          >
            {showNotificaction && (
              <div className={styles.notificationWrapper}>
                <img src={CheckIcon} alt="check icon" />
                <div className={styles.notificationText}>
                  {notificationMessage}
                </div>
                <img
                  className={styles.closeIcon}
                  src={CloseIcon}
                  alt="close icon"
                  onClick={() => setShowNotification(false)}
                />
              </div>
            )}
            <div className={styles.title}>{title}</div>
            {subtitle && <div className={styles.subTitle}>{subtitle}</div>}
          </div>
        )}

        <div
          className={styles.contentWrapper}
          style={{ width: '320px', ...mainContentStyle }}
        >
          {children}

          {showButton && buttonText && (
            <div
              className={styles.buttonWrapper}
              style={{ width: '320px', ...mainContentStyle }}
            >
              <Button
                type="submit"
                colorScheme={buttonColorScheme}
                text={buttonText}
                onClick={buttonOnClick}
                disabled={buttonDisabled}
              />

              {showSecondaryButton && secondaryButtonText && (
                <div className={styles.secondaryButton}>
                  <Button
                    type="submit"
                    colorScheme={secondaryButtonColorScheme}
                    text={secondaryButtonText}
                    onClick={secondaryButtonOnClick}
                    disabled={secondaryButtonDisabled}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
