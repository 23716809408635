import styles from './styles.module.scss';
import mapImg from '../../assets/map.png';

interface Props {
  name: string;
  address?: string;
  city?: string;
  onSelect?: () => void;
}

export function LocationCard({ onSelect, name, address, city }: Props) {
  return (
    <a
      href="https://goo.gl/maps/wS1QMYu7biRKhigu6"
      target="_blank"
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <div className={styles.container} onClick={onSelect}>
        <div>
          <p>{name}</p>
          <p>{address}</p>
          <p>{city}</p>
        </div>

        <img src={mapImg} alt="Map with clinic address" />
      </div>
    </a>
  );
}
