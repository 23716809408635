import { HTMLInputTypeAttribute } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { get as _get } from 'lodash';
import styles from './styles.module.scss';

interface Props {
  form: UseFormReturn<any, any>;
  mask: string | (string | RegExp)[];
  name: string;
  validation?: any;
  autofocus?: boolean;
  type?: HTMLInputTypeAttribute;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  pattern?: string;
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'numeric'
    | 'decimal';
}
export function MaskedTextField({
  form,
  mask,
  name,
  type,
  placeholder,
  validation,
  autofocus,
  className,
  inputClassName,
  inputMode,
  pattern,
}: Props) {
  const error =
    form.formState.errors[name] || _get(form.formState.errors, name.split('.'));
  return (
    <Controller
      name={name}
      control={form.control}
      rules={validation}
      render={({ field }) => (
        <div className={`${styles.container} ${className && className}`}>
          <InputMask
            mask={mask}
            maskPlaceholder={null}
            ref={field.ref}
            name={field.name}
            inputMode={inputMode}
            pattern={pattern}
            type={type || 'text'}
            className={`${styles.input} ${inputClassName && inputClassName}`}
            placeholder={placeholder}
            value={field.value || ''}
            onBlur={field.onBlur}
            onChange={field.onChange}
            autoFocus={!!autofocus}
          />
          {error && (
            <div className={styles.errorMessage}>
              {error?.message || 'Invalid input'}
            </div>
          )}
        </div>
      )}
    />
  );
}
