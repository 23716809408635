import dayjs from 'dayjs';
import { mockAppointment, mockPatient, mockSchedule } from '../mock/mock_data';
import { Patient, PlannedAppointment, User } from '../types';
import { AppointmentType } from '../utils/enums';
import { LoggingEventTypes } from '../utils/logging/event';
import { identifyUser, trackEvent } from '../utils/logging/logging';

const BASE_URL =
  process.env.REACT_APP_CLOUD_FUNCTIONS_BASE_URL ||
  'http://localhost:5002/rinse-dental-app-staging';

export const fetchTimeSlots = async (
  appointmentType: AppointmentType | string,
  extraData?: any
) => {
  if (process.env.REACT_APP_MOCK_DATA === 'true') {
    return mockSchedule(10);
  }

  const url = `${BASE_URL}/getAvailableTimeSlots`;
  const body = JSON.stringify({
    data: {
      dateStart: dayjs().format('YYYY-MM-DD'),
      dateEnd: dayjs().add(8, 'week').format('YYYY-MM-DD'),
      appointmentType,
      isNewPatient: true,
      ...extraData,
    },
  });

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    const data = await res.json();
    return data.result;
  } catch (error: any) {
    const errorMessage: string = error.toString() ?? '';
    trackEvent(LoggingEventTypes.FailedHttpCall, {
      url,
      body,
      error: errorMessage,
    });
  }
};

export const createPatient = async (
  user: User
): Promise<{ result: Patient } | null> => {
  if (process.env.REACT_APP_MOCK_DATA === 'true') {
    return mockPatient();
  }

  const url = `${BASE_URL}/createPatient`;
  const body = JSON.stringify({
    data: {
      firstname: user.firstName,
      lastname: user.lastName,
      birthdate: user.birthday,
      phoneNumber: user.phoneNumber,
      email: user.email,
      hasDentalInsurance: user.hasDentalInsurance,
    },
  });

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    const data = await res.json();

    identifyUser(user.phoneNumber);
    return data;
  } catch (error: any) {
    const errorMessage: string = error.toString() ?? '';
    trackEvent(LoggingEventTypes.FailedHttpCall, {
      url,
      body,
      error: errorMessage,
    });
  }

  return null;
};

export const createAppointment = async (user: User, patientId: string) => {
  if (process.env.REACT_APP_MOCK_DATA === 'true') {
    return mockAppointment();
  }
  const url = `${BASE_URL}/createWebSchedAppointmentFromWebsite`;
  const body = JSON.stringify({
    data: {
      slotTimeStart: user.timeslot.DateTimeStart,
      slotTimeEnd: user.timeslot.DateTimeEnd,
      providerId: +user.timeslot.ProvNum,
      operatoryNumber: +user.timeslot.OpNum,
      patientId: patientId,
      appointmentType: user.service,
      phoneNumber: user.phoneNumber,
      hasDentalInsurance: user.hasDentalInsurance,
      referredBy: user.referredBy,
      ...(user.hasOwnProperty('isInPain') && { isInPain: user.isInPain }),
      ...(user.reasonText && { reasonForUrgentCare: user.reasonText }),
    },
  });

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    return await res.json();
  } catch (error: any) {
    const errorMessage: string = error.toString() ?? '';
    trackEvent(LoggingEventTypes.FailedHttpCall, {
      url,
      body,
      error: errorMessage,
    });
  }
  return null;
};

export const scheduleTreatmentAppointment = async (
  appt: PlannedAppointment
): Promise<any> => {
  if (process.env.REACT_APP_MOCK_DATA === 'true') {
    return mockAppointment();
  }

  const url = `${BASE_URL}/schedulePlannedAppointment`;
  const body = JSON.stringify({
    data: {
      dateTime: appt.dateTime,
      AptNum: +appt.aptNum,
      operatoryNumber: +appt.operatoryNumber,
      providerNumber: +appt.providerNumber,
    },
  });

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    const data = await res.json();
    return data;
  } catch (error: any) {
    const errorMessage: string = error.toString() ?? '';
    trackEvent(LoggingEventTypes.FailedHttpCall, {
      url,
      body,
      error: errorMessage,
    });
  }
  return null;
};

export const trackEmail = async (email: string) => {
  const url = `${BASE_URL}/trackEmail`;
  const body = JSON.stringify({
    data: {
      email,
    },
  });

  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body,
    });
    const data = await res.json();
    return data;
  } catch (error: any) {
    const errorMessage: string = error.toString() ?? '';
    trackEvent(LoggingEventTypes.FailedHttpCall, {
      url,
      body,
      error: errorMessage,
    });
  }
  return null;
};

export const verifyInsuranceInfo = async ({
  firstName,
  lastName,
  dob,
  memberId,
  carrier,
  isSubscriber,
}: {
  firstName: string;
  lastName: string;
  dob: string;
  memberId: string;
  carrier: string;
  isSubscriber: boolean;
}) => {
  const url = `${BASE_URL}/verifyCustomerInsurance`;
  const body = JSON.stringify({
    data: {
      firstName,
      lastName,
      dob,
      memberId,
      carrier,
      isSubscriber,
    },
  });

  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });
};

type CreateCustomerInsuranceInput = {
  relationship: {
    id: string;
    name: string;
  };
  payer: {
    id: string;
    name: string;
    source: string;
  };
  patient: PatientType;
  policyHolder: PatientType;
  phoneNumber: string;
  patientId: string;
};

type PatientType = {
  membership: string;
  firstname: string;
  lastname: string;
  birthdate: string;
};

export const createCustomerInsurance = async (
  data: CreateCustomerInsuranceInput
) => {
  const url = `${BASE_URL}/createCustomerInsurance`;
  const body = JSON.stringify({
    data,
  });

  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body,
  });
};

export const getOnederfulCarries = async () => {
  const url = `${BASE_URL}/getOnederfulCarries`;

  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: {} }),
  });
};

export const getODCarries = async () => {
  const url = `${BASE_URL}/getCarriers`;

  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: {} }),
  });
};

export const createStripeSetupIntent = async ({
  patNum,
  maskedId,
}: {
  patNum?: string;
  maskedId?: string;
}): Promise<any> => {
  const url = `${BASE_URL}/createStripeSetupIntent`;

  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { maskedId, patNum } }),
  });
};

export const getMediaFiles = async ({
  maskedId,
}: {
  maskedId?: string;
}): Promise<any> => {
  const url = `${BASE_URL}/getCustomerMediaData`;

  return await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ data: { maskedId, type: 'images' } }),
  });
};
