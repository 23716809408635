import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { Card } from '../../components/Card';
import { TextField } from '../../components/form/TextField';
import { MaskedTextField } from '../../components/form/MaskedTextField';
import { useSchedule } from '../../providers/main';
import { ISteps } from '../../types';
import styles from './styles.module.scss';
import { PatientError } from '../../utils/errors';
import { ErrorType, LocalStorageKey } from '../../utils/enums';
import { formatAppointmentName } from '../../utils/format';
import { SlotAlreadyBookedModal } from '../../components/modal/SlotAlreadyBookedModal';
import { trackEvent, trackScreen } from '../../utils/logging/logging';
import { LoggingEventTypes } from '../../utils/logging/event';
import { EMAIL_REGEX } from '../../utils/constants';

export function Confirm({ next, progress, back, moveToStep }: ISteps) {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setErrorMessage] = useState('');
  const [loadingRequest, setLoadingRequest] = useState(false);
  const form = useForm({
    defaultValues: {
      firstName: sessionStorage.getItem('firstName') || '',
      lastName: sessionStorage.getItem('lastName') || '',
      phoneNumber: sessionStorage.getItem('phoneNumber') || '',
      hasDentalInsurance: true,
    },
  });
  const { handleSubmit, watch } = form;

  // save information to session storage so that it can be retrieved if the user comes back to the form
  useEffect(() => {
    const subscription = watch((data) => {
      sessionStorage.setItem('firstName', data.firstName || '');
      sessionStorage.setItem('lastName', data.lastName || '');
      sessionStorage.setItem('phoneNumber', data.phoneNumber || '');
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch]);

  const {
    updateUser,
    handleUserSubmit,
    user: userValue,
    fetchAvailability,
  } = useSchedule();
  const formattedServiceName = formatAppointmentName(userValue.service!);

  function onSubmit(data: any) {
    if (!data.phoneNumber || data.phoneNumber.length !== 17) {
      setErrorMessage('Invalid phone number length');
      return;
    }

    toast.loading('Booking...');
    setLoadingRequest(true);

    let user = {
      ...userValue,
      ...data,
    };
    setErrorMessage('');

    updateUser(user);
    handleUserSubmit(user)
      .then(() => {
        if (localStorage.getItem(LocalStorageKey.IsNewCustomer)) {
          next();
        } else {
          navigate('done');
        }
      })
      .catch((error: Error) => {
        let message =
          error?.message ??
          "An account exists with the same phone number but the information doesn't match. Please try again or contact us at (415) 440-9000. ";

        if (error instanceof PatientError) {
          if (error.errorType === ErrorType.PatientExist) {
            message = error.message;
          } else if (error.errorType === ErrorType.UnavailableTime) {
            fetchAvailability(); // fetch slots again
            setIsModalOpen(true);
          }
        }
        setErrorMessage(message);
      })
      .finally(() => {
        trackEvent(LoggingEventTypes.Book);
        toast.dismiss();
        setLoadingRequest(false);
      });
  }

  useEffect(() => {
    trackScreen('ConfirmScreen');
  }, []);

  return (
    <Card
      progress={progress}
      title="Let’s confirm the details"
      back={() => {
        setLoadingRequest(false);
        setErrorMessage('');
        back();
      }}
      showButton
      buttonText="Book Now"
      buttonDisabled={loadingRequest}
      buttonOnClick={handleSubmit(onSubmit)}
    >
      <form className={styles.container}>
        <fieldset disabled={loadingRequest}>
          <p className={styles.message}>
            Your {formattedServiceName || 'appointment'} will be on{' '}
            {dayjs(userValue.timeslot?.DateTimeStart).format('ddd, MMM D')} at{' '}
            {dayjs(userValue.timeslot?.DateTimeStart).format('h:mm A')} PT{' '}
            <span style={{ whiteSpace: 'nowrap' }}>
              {`at ${userValue.location?.name}`}
            </span>
          </p>
          <TextField
            form={form}
            name="firstName"
            placeholder="First Name"
            validation={{
              required: { value: true, message: 'Enter your first name' },
            }}
            value={userValue.firstName}
          />
          <TextField
            form={form}
            name="lastName"
            placeholder="Last Name"
            validation={{
              required: { value: true, message: 'Enter your last name' },
            }}
            value={userValue.lastName}
          />
          <MaskedTextField
            mask="+1 (999) 999-9999"
            placeholder="Phone Number"
            form={form}
            name="phoneNumber"
            inputMode="tel"
            pattern="\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}"
            validation={{
              required: { value: true, message: 'Enter your phone number' },
            }}
          />
          <TextField
            form={form}
            name="email"
            validation={{
              required: { value: true, message: 'Enter your email address' },
              pattern: {
                value: EMAIL_REGEX,
                message: 'Enter a valid email address',
              },
            }}
            value={userValue.email}
            placeholder="Email"
          />

          {error.length > 0 && <div className={styles.error}>{error}</div>}
        </fieldset>
      </form>
      <SlotAlreadyBookedModal
        isOpen={isModalOpen}
        onClick={() => navigate(-1)}
      />
    </Card>
  );
}
