/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from './routes';

import { Location } from './screens/Location';
import { VisitType } from './screens/VisitType';
import { DateTime } from './screens/DateTime';
import { Confirm } from './screens/Confirm';
import { NotFound } from './screens/NotFound';

import { UserProvider } from './providers/main';
import styles from './styles/layout.module.scss';
import { InsuranceHelpScreen } from './screens/Insurance';
import { PaymentScreen } from './screens/Payment';
import { InsuranceCarrierSelection } from './screens/InsuranceCarrierSelection';
import { DoneScreen } from './screens/Done';
import { MediaScreen } from './screens/Media';

const STEPS = {
  [ROUTES.selectVisitType]: '/',
  [ROUTES.selectLocation]: '/location',
  [ROUTES.selectDates]: '/schedule',
  [ROUTES.confirm]: '/confirm',
  [ROUTES.insuranceCarrierSelection]: '/insurance-carrier-selection',
  [ROUTES.addInsurance]: '/add-insurance',
  [ROUTES.payment]: '/payment',
  [ROUTES.allDone]: '/done',
};

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  const [step, setStep] = useState(location.pathname);
  const steps = Object.values(STEPS);
  const progress =
    ((steps.findIndex((e) => e === step) + 1) / steps.length) * 100;

  const isDifferentScreen = step !== location.pathname;

  const nextStep = () => {
    const index = steps.indexOf(step);
    if (index >= 0 && index < steps.length)
      setTimeout(() => setStep(steps[index + 1]), 200);
  };

  const previousStep = () => {
    const index = steps.indexOf(step);
    if (index > 0 && index <= steps.length)
      setTimeout(() => setStep(steps[index - 1]), 200);
  };

  const moveToStep = (step: any) => {
    setStep(step);
  };

  useEffect(() => {
    //Navigate when you interact with the app (next screen, go back, etc...)
    isDifferentScreen && navigate(step);
  }, [step]);

  useEffect(() => {
    //Navigate when you access the screen by URL
    isDifferentScreen && setStep(location.pathname);
  }, [location]);

  return (
    <main className={styles.home}>
      <UserProvider>
        <Routes>
          <Route
            path={STEPS[ROUTES.selectVisitType]}
            element={
              <VisitType
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />
          <Route
            path={`${STEPS[ROUTES.selectVisitType]}referrer/:referrer`}
            element={
              <VisitType
                progress={progress}
                moveToStep={moveToStep}
                next={() => moveToStep(STEPS[ROUTES.selectLocation])}
                back={() => navigate(-1)}
              />
            }
          />
          <Route
            path={STEPS[ROUTES.selectLocation]}
            element={
              <Location
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />
          <Route
            path={STEPS[ROUTES.selectDates]}
            element={
              <DateTime
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />
          <Route
            path={'consult/:consultType/:userIdentifier'}
            element={
              <DateTime
                progress={progress}
                moveToStep={moveToStep}
                next={() => navigate(STEPS[ROUTES.confirm])}
                back={() => {}}
              />
            }
          />
          <Route
            path={'next-visit/:identifier'}
            element={
              <DateTime
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={previousStep}
              />
            }
          />
          <Route
            path={STEPS[ROUTES.confirm]}
            element={
              <Confirm
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />

          <Route
            path={STEPS[ROUTES.payment]}
            element={
              <PaymentScreen
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />
          <Route path={STEPS[ROUTES.allDone]} element={<DoneScreen />} />
          <Route
            path={STEPS[ROUTES.insuranceCarrierSelection]}
            element={
              <InsuranceCarrierSelection
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />
          <Route
            path={STEPS[ROUTES.addInsurance]}
            element={
              <InsuranceHelpScreen
                progress={progress}
                moveToStep={moveToStep}
                next={nextStep}
                back={() => navigate(-1)}
              />
            }
          />
          <Route path={ROUTES.done} element={<DoneScreen />} />

          <Route path={'media/:identifier/'} element={<MediaScreen />} />

          <Route path="*" element={<NotFound />} />
        </Routes>

        <Toaster />
      </UserProvider>
    </main>
  );
}

export default App;
