import dayjs from 'dayjs';
import { TimeSlot } from '../types';

export function dateHashBuilder(timeSlot: TimeSlot): string {
  return `${timeSlot.DateTimeStart}|${timeSlot.DateTimeEnd}|${timeSlot.OpNum}|${timeSlot.ProvNum}`;
}

export function dateHashDecrypt(hash: string): TimeSlot {
  const slotFiedls = ['DateTimeStart', 'DateTimeEnd', 'OpNum', 'ProvNum'];
  const timeSlot = hash.split('|').reduce((acc, value, index) => {
    return { ...acc, [slotFiedls[index]]: value };
  }, {});
  return timeSlot as TimeSlot;
}

export function dateParser(date: string) {
  return dayjs(date).format('MM/DD/YYYY');
}
